import React, { Component, Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import './App.css';
import Login from './auth_components/js/Login';
import HomePage from './Containers/HomePage';
import LogBookContainer from './Containers/LogBookContainer';

import RouteLoading from './Components/Essentials/RouteLoading';

const ForgotPasswordContainer = React.lazy(() => import('./Containers/ForgotPasswordContainer'));
const SettingsContainer = React.lazy(() => import('./Containers/SettingsContainer'));
const AttendanceContainer = React.lazy(() => import('./Containers/AttendanceContainer'));
const RoutineContainer = React.lazy(() => import('./Containers/RoutineContainer'));
const ClasstestContainer = React.lazy(() => import('./Containers/ClasstestContainer'));
const FeedbackContainer = React.lazy(() => import('./Containers/FeedbackContainer'));
const AssesmentReportContainer = React.lazy(() => import('./Containers/AssesmentReportContainer'));
const AssignmentContainer = React.lazy(() => import('./Containers/AssignmentContainer'));






class App extends Component {

  render() {
    return (
      <React.Fragment>

        <Suspense fallback={<RouteLoading />}>
          <Router history={history} >
            <div>
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/forgotpassword" component={ForgotPasswordContainer} />
                <Route exact path="/" render={(props) => <HomePage {...props} />} />
                <Route exact path="/settings" component={SettingsContainer} />
                <Route exact path="/attendance" component={AttendanceContainer} />
                <Route exact path="/takeattendance" component={AttendanceContainer} />
                <Route exact path="/editattendance" component={AttendanceContainer} />
                <Route exact path="/attendancereport" component={AttendanceContainer} />
                <Route exact path="/classtest" component={ClasstestContainer} />
                <Route exact path="/classtest/addmarks" component={ClasstestContainer} />
                <Route exact path="/classtest/singlereport" component={ClasstestContainer} />

                <Route exact path="/assesmentreport" component={AssesmentReportContainer} />


                <Route exact path="/routine" component={RoutineContainer} />
                <Route exact path="/logbook" component={LogBookContainer} />
                <Route exact path="/feedback" component={FeedbackContainer} />
                <Route exact path="/feedback/:id" component={FeedbackContainer} />

                <Route exact path="/assignment" component={AssignmentContainer} />
                <Route exact path="/assignment/check" component={AssignmentContainer} />



              </Switch>
            </div>
          </Router>
        </Suspense>
      </React.Fragment>
    );
  }
}

export default App;
