import React from 'react'
import { makeStyles, CircularProgress, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        top: 0,
        left: 0,
        overflow: 'hidden',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'white',
        zIndex: 1006,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }
}))

export default function RouteLoading({}){
    let classes = useStyles();
    return(
        <React.Fragment>
            <div className={classes.root}>
                <CircularProgress />
                <br />
                <Typography 
                    align="center"
                    variant="caption"
                >
                    Loading Your App...<br />Please Wait
                </Typography>
            </div>
        </React.Fragment>
    )
}