import React, { Component } from 'react';
import withAuth from './../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';

import LogBookContent from '../Components/LogBook/LogBookContent';

class LogBookContainer extends Component {
    constructor(props) {
        super(props);

        let us = JSON.parse(localStorage.getItem('user'));
        let username = us.user.email;

        this.state = {
            username: username,
            apikey: us.user.api_key
        }

    }
    render() {
        return (
            <div>
                <Navbar history={this.props.history} username={this.state.username} apikey={this.state.apikey} />
                <div className="main-body">
                    <LogBookContent
                        history={this.props.history}
                        username={this.state.username}
                        apikey={this.state.apikey} />
                </div>
            </div>
        )
    }
}

export default withAuth(LogBookContainer);