import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Grid, Card, CardContent, Typography, CardActions, Button, withStyles, CardHeader, Badge, Avatar, Fade } from '@material-ui/core';
import Grade from '@material-ui/icons/Grade';
import Schedule from '@material-ui/icons/Schedule';
import Commute from '@material-ui/icons/Commute';
import Receipt from '@material-ui/icons/Receipt';
import { GetData } from '../../api/service';
import { purple, red, pink, blue, teal, lightBlue, green } from '@material-ui/core/colors';
import RoutineLanding from '../Routine/RoutineLanding';


const styles = theme => ({
    card: {
        minWidth: 275,
    },
    details: {
        display: 'flex',
        flexDirection: 'row'
    },
    content: {
        flex: '1 0 auto'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 22,
    },
    pos: {
        marginBottom: 12,
    },
    backPrimary: {
        backgroundColor: '#d500f9',
        color: 'white',
        display: 'flex',
        justifyContents: 'center',
        alignItems: 'center'
    },
    backSecondary: {
        backgroundColor: '#e91e63',
        color: 'white',
        display: 'flex',
        justifyContents: 'center',
        alignItems: 'center'
    },
    backSec: {
        backgroundColor: '#009688',
        color: 'white',
        display: 'flex',
        justifyContents: 'center',
        alignItems: 'center'
    },
    approve: {
        backgroundColor: purple['A400']
    },
    approveGrid: {

    },
    bigCard: {
        backgroundColor: lightBlue[600],
    },

});

const colorArr = [purple['A200'], red[500], pink['A200'], blue['A400'], teal[700]]


class TopLabel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }


    render() {
        const { classes } = this.props;

        let i = [];
        

        return (
            <div>

                
                <RoutineLanding
                    {...this.props}
                />
               

            </div >
        )
    }
}


export default withStyles(styles)(TopLabel);


