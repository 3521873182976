import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import AuthService from './../auth_components/js/AuthService';
import { Drawer, Divider, List, ListItem, ListItemIcon, ListItemText, Collapse, Typography, ListItemSecondaryAction, Badge, Chip, Fade } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Dashboard from '@material-ui/icons/Dashboard';
import Assignment from '@material-ui/icons/Assignment';
import Work from '@material-ui/icons/Work';
import Person from '@material-ui/icons/Person';

import QueryBuilder from '@material-ui/icons/QueryBuilder';
import Receipt from '@material-ui/icons/Receipt';
import Label from '@material-ui/icons/Label';
import DateRange from '@material-ui/icons/DateRange';
import Description from '@material-ui/icons/Description';
import EventAvailable from '@material-ui/icons/EventAvailable';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import Money from '@material-ui/icons/Money';
import Notifications from '@material-ui/icons/Notifications';
import { red } from '@material-ui/core/colors';
import { GetData } from './../api/service'

const drawerWidth = 240;

const styles = theme => ({
    root: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        backgroundColor: '#70012b'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    toolbar: {
        ...theme.mixins.toolbar,
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing.unit * 2


    },
    navIconHide: {
        display: 'block',
        [theme.breakpoints.up('xl')]: {
            display: 'none'
        }
    },
    drawerPaper: {
        width: drawerWidth,
        left: 0,
        [theme.breakpoints.up('lg')]: {
            width: drawerWidth
        }
    },
    listSmall: {
        paddingLeft: '35px'
    },
    notificationCount: {
        backgroundColor: red[400],
        fontSize: 12,
        color: 'white',
        padding: '5px',
        borderRadius: '15px',
        width: '30px',
        textAlign: 'center'
    }
});





class DrawerMain extends React.Component {
    constructor(props) {
        super(props);

        let user = JSON.parse(localStorage.getItem('user'));
        let is_teaching = user.user.is_teaching;
        let name = user.user.name;
        let access = user.user.access;
        //console.log(access)


        let menuListObject = [];

        if (is_teaching) {
            menuListObject = [
                {
                    title: "Dasboard",
                    link: "/",
                    submenu: [],
                    icon: <Dashboard />
                },
                {
                    title: "Class Routine",
                    link: "/routine",
                    submenu: [],
                    icon: <QueryBuilder />
                },
                {
                    title: "Logbook",
                    link: "/logbook",
                    submenu: [],
                    icon: <Assignment />
                },
                {
                    title: "Internal Assesment",
                    link: "/classtest",
                    submenu: [],
                    icon: <EventAvailable />
                },
                {
                    title: "Assesment Reports",
                    link: "/assesmentreport",
                    submenu: [],
                    icon: <Description />
                },
                {
                    title: "Feedback",
                    link: "/feedback",
                    submenu: [],
                    icon: <Work />
                },
                {
                    title: "Assignment",
                    link: "/assignment",
                    submenu: [],
                    icon: <Assignment />
                },


            ]
        } else {
            menuListObject = [
                {
                    title: "Dasboard",
                    link: "/",
                    submenu: [],
                    icon: <Dashboard />
                }

            ]
        }


        // put access parameters

        if (Array.isArray(access) && access.length > 0) {
            access.map((el, index) => {
                if (el.access_field === 'ADM') {
                    let d = {
                        title: "Admission",
                        link: "/admission",
                        submenu: [],
                        icon: <Label />
                    }

                    menuListObject.push(d);
                }

                if (el.access_field === 'FEE') {
                    let d = {
                        title: "Fees Collection",
                        link: "/fees",
                        submenu: [],
                        icon: <Money />
                    }

                    menuListObject.push(d);
                }

                if (el.access_field === 'LEAVEHOD') {
                    let d = {
                        title: "Approve Leave",
                        link: "/leaveapprove",
                        submenu: [],
                        icon: <DateRange />
                    }

                    menuListObject.push(d);
                }

                if (el.access_field === 'LEAVEPRINCE') {
                    let d = {
                        title: "Approve Leave (Principal)",
                        link: "/leavegrant",
                        submenu: [],
                        icon: <DateRange />
                    }

                    menuListObject.push(d);
                }

                if (el.access_field === 'REP') {
                    let d = {
                        title: "Reports",
                        link: "/reports",
                        submenu: [],
                        icon: <Person />
                    }

                    menuListObject.push(d);
                }
            })
        }



        this.state = {
            anchorEl: null,
            mobileMoreAnchorEl: null,
            open: [false, false, false, false],
            menuListObject: menuListObject,
            name: name,
        }



    }

    handleClick = (e) => {
        let open = this.state.open;
        open[e] = !this.state.open[e];
        this.setState({
            open
        })
    }

    componentDidMount() {

    }





    handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
        this.handleMobileMenuClose();
    };

    handleMobileMenuOpen = event => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null });
    };

    render() {
        const { anchorEl, mobileMoreAnchorEl } = this.state;
        const { classes } = this.props;




        const menuListObject = this.state.menuListObject;

        let menu = [];

        menu = menuListObject.map((el, index) => {

            let i = [];
            let j = [];
            if (el.submenu.length > 0) {
                if (this.state.open[index]) {
                    i = <ExpandLess />
                    j =
                        <Collapse in={this.state.open[index]} timeout="auto" unmountOnExit>
                            <List>
                                {el.submenu.map((el, index) =>
                                    <Link to={el.link} key={index}>
                                        <ListItem button className={classes.listSmall}>
                                            <ListItemText>
                                                <Typography variant="body1">{el.title}</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </Link>
                                )}
                            </List>
                        </Collapse>
                } else {
                    i = <ExpandMore />
                    j = [];
                }
            }
            return <List disablePadding key={index} >
                {(el.link !== "") ? <Link to={el.link}>
                    <ListItem button onClick={this.handleClick.bind(this, index)}>
                        <ListItemIcon>
                            {el.icon}
                        </ListItemIcon>
                        <ListItemText >
                            <Typography variant="body2">{el.title}</Typography>
                        </ListItemText>
                        {(el.submenu.length > 0) ? i : null}

                    </ListItem>
                </Link> : <ListItem button onClick={this.handleClick.bind(this, index)}>
                        <ListItemIcon>
                            {el.icon}
                        </ListItemIcon>
                        <ListItemText >
                            <Typography variant="body2">{el.title}</Typography>
                        </ListItemText>
                        {(el.submenu.length > 0) ? i : null}
                    </ListItem>}
                {j}
            </List>
        }


        )



        return (
            <div >

                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    anchor="left"
                >

                    <div className={classes.toolbar}>
                        <Typography
                            variant="h6"
                        >
                            Teacher's Portal

                        </Typography>
                        <Typography>
                            {this.state.name}
                        </Typography>
                    </div>
                    <Divider />
                    {menu}
                </Drawer>
            </div>
        );
    }
}

DrawerMain.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DrawerMain);
