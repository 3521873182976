import React, { Component } from 'react'
import LogBookTable from './LogBookTable';
import { GetData } from '../../api/service';

export default class LogBookContent extends Component {

    state = {
        table_data: [],
        isLoading: true
    }

    componentDidMount() {
      GetData(`/${this.props.apikey}/${this.props.username}/gettopics`)
      .then((resp) => {
          console.log(resp);
          this.setState({
              table_data: resp,
              isLoading: false
          })
      })
    }
    
    render() {

        return (
            <div>

                {!this.state.isLoading ?
                    <LogBookTable
                        table_data={this.state.table_data}
                        isLoading={this.state.isLoading}
                        history={this.props.history}
                    />
                    : null}

            </div>
        )
    }
}
