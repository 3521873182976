import decode from 'jwt-decode';
export default class AuthService {
    constructor(domain) {
        this.domain = domain || process.env.NODE_ENV === 'production' ? 'https://backend.birjhoramahavidyalaya.ac.in/public' : 'http://localhost/birjhora/api/public';
        this.fetch = this.fetch.bind(this)
        this.login = this.login.bind(this)
        this.getProfile = this.getProfile.bind(this)
        this.setUser = this.setUser.bind(this)
        this.getUser = this.getUser.bind(this);
    }

    login(username, password) {
        // Get a token
        return this.fetch(`${this.domain}/generaluser/login`, {
            method: 'POST',
            body: JSON.stringify({
                email: username,
                password
            })
        }).then(res => {
            if(res.hasOwnProperty('token')){
                //console.log(res)
                this.setToken(res.token);
                this.setUser(res);
                this.setApikey(res.user.apikey);
                this.setArray(res.user.access);
                return Promise.resolve(res);
            }else{
                throw "error";
            }
        })
        .catch(function (err) {
            throw err
        })
    }
    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken()
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) {
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }
    setUser(user){
        const val = JSON.stringify(user);
        localStorage.setItem('user',val);
    }

    setApikey(key){
        const val = JSON.stringify(key);
        localStorage.setItem('apikey', val);
    }

    getUser() {
        return JSON.parse(localStorage.getItem('user'));
    }
    setToken(idToken) {
        // Saves user token to localStorage
        localStorage.setItem('id_token', idToken)
    }

    setArray(access) {
        // Saves user token to localStorage
        localStorage.setItem('access', access)
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('id_token')
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.removeItem('user');
        localStorage.removeItem('id_token');
        localStorage.removeItem('apikey');
    }

    getProfile() {
        let user = this.getUser();
        return user;
    }


    fetch(url, options) {
        // performs api calls sending the required authentication headers
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Unit': 'FACULTY'
        }

        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken()
        }

        return fetch(url, {
            headers,
            ...options
        })
        .then(this._checkStatus)
        .catch(function (err) {
            throw err;
        })
        .then(response => response.json())
        .catch(function (err) {
            throw err
        })
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) {
            return response
        } else {
            let error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }
}