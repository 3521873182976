import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton, Button } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import Add from '@material-ui/icons/Add';



export default class LogBookTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false
        }
    }



    handleDelete(rowsDeleted) {
        console.log(rowsDeleted)
    }

    onActionClick(el) {
        this.props.actionClick(el);
    }

    onClick() {
        console.log("hey");
    }



    render() {
        const columns = [
            {
                name: "Date",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Course",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Stream",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Subject",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Paper Code",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Semester",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Topic",
                options: {
                    filter: false,
                    sort: false,
                }
            },
            {
                name: "Edit",
                options: {
                    filter: false,
                    sort: false,
                }
            }

        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: 'none',
            responsive: 'scroll',
            onRowsDelete: (rowsDeleted) => {
                this.handleDelete(rowsDeleted)
            },
            
        };

        let data = [];
        let table_data = this.props.table_data;
        if (table_data.length > 0) {
            data = table_data.map((el, index) =>
                [
                    el.date1, 
                    el.course, 
                    el.stream, 
                    el.sub_name, 
                    el.paper_code, 
                    el.semester, 
                    el.topic,
                    <Button
                        size="small"
                        onClick={() => this.props.history.push('/editattendance', el.id)}
                    >
                        Edit
                    </Button>
                ]
            )
        } else {
            data = [];
        }

        return (
            <div>
                <MUIDataTable
                    title={"Topic"}
                    data={data}
                    columns={columns}
                    options={options}

                />
            </div>
        )


    }
}
