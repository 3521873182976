import React, { Component } from 'react';
import './css/welcome.css';
import { GetData, PostData } from '../../api/service';
import { Typography } from '@material-ui/core';
import TopLabel from '../DashBoard/TopLabel';


export default class Welcome extends Component {  
  render() {
    return (
      <div>
       <TopLabel
        username={this.props.username}
        {...this.props}
       />
      </div>
    )
  }
}
